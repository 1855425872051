import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./homepage.css";
import "react-toastify/dist/ReactToastify.css";
import { Outlet } from 'react-router-dom';
import { useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Navbar from "../components/common/Navbar";
import Footer from "../components/common/Footer";

export default function Layout() {
    const [searchParams] = useSearchParams();
    useEffect(() => {
      AOS.init();
      AOS.refresh();
      if (searchParams.has("logout")) {
        ShowSRAMNotification();
      }
    }, []);
  
    const ShowSRAMNotification = () =>
      toast.info(
        "Successfully logged out from MDR." +
          "But your single sign-on session with SRAM is still active." +
          " To log out of all SRAM connected services, close your browser.",
        {
          toastId: "warning1",
        }
      );
  return (
    <>
      <ToastContainer />
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}
