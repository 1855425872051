import React from "react";
import styled from "styled-components";
import MUMCLogo from "../../assets/logos/mumc_light.svg";
import UMLogo from "../../assets/logos/UM-logo-white.png";
import MDR from "../../assets/logos/MDR_logo_white_no_text.png";
import { device } from "../../assets/Breakpoints";

const FooterLogos = () => {
  return (
    <LogoContainer>
      <UM src={UMLogo} alt="um" />
      <DH src={MDR} alt="mdr" />
      <MUMC src={MUMCLogo} alt="mumc" />
    </LogoContainer>
  );
};

export default FooterLogos;

const LogoContainer = styled.div`
  margin: 0 auto 1rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.tablet} {
    flex-direction: row;
  }
  @media ${device.laptop} {
    width: 600px;
  }
`;
const MUMC = styled.img`
  object-fit: contain;
  width: 200px;
  height: 30px;
  @media ${device.tablet} {
    height: 50px;
    width: 220px;
  }
`;

const DH = styled.img`
  object-fit: contain;
  width: 200px;
  height: 30px;
  margin: 16px 0;
  @media ${device.tablet} {
    margin: 0;
  }
`;

const UM = styled.img`
  object-fit: contain;
  width: 200px;
  height: 30px;

  @media ${device.tablet} {
    height: 50px;
    width: 220px;
  }
`;
