import Section from "../common/Section";
import "./accessmanagement.css";
import viewerIcon from "../../assets/icons/viewer.svg";
import contributorIcon from "../../assets/icons/contributor.svg";
import managerIcon from "../../assets/icons/manager.svg";
import SURFLogo from "../../assets/logos/SURF_Logo.png";
import React from "react";
import Title from "../common/Title";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaRegWindowClose } from "react-icons/fa";

const abilitiesText = [
  "view data",
  "download data",
  "search data",
  "add data",
  "delete data",
  "manage settings",
  "manage permissions",
];

const roles = [
  {
    icon: viewerIcon,
    title: "Viewer",
    abilities: [true, true, true, false, false, false, false],
  },
  {
    icon: contributorIcon,
    title: "Contributor",
    abilities: [true, true, true, true, true, false, false],
  },
  {
    icon: managerIcon,
    title: "Manager",
    abilities: [true, true, true, true, true, true, true],
  },
];

const AbilityRow = ({ obj, index }) => {
  let icon = "";
  if (obj) {
    icon = <FaRegCheckCircle className={"icon-green"} />;
  } else {
    icon = <FaRegWindowClose className={"icon-red"} />;
  }
  return (
    <div className={"ability"}>
      <div>{icon}</div>
      <div>{abilitiesText[index]}</div>
    </div>
  );
};

const Role = ({ icon, title, abilities }) => {
  return (
    <div className={"project-level-authorization-role"}>
      <div className={"project-level-authorization-role-icon"}>
        <img src={icon} alt="Viewer" />
      </div>
      <div className={"project-level-authorization-role-title"}>{title}</div>
      <div className={"project-level-authorization-role-text"}>
        {abilities.map(function (object, i) {
          return <AbilityRow obj={object} key={i} index={i} />;
        })}
      </div>
    </div>
  );
};

const AccessManagement = () => {
  return (
    <Section name={"access-management"}>
      <div className={"dark-blue-box"}></div>
      <div className={"white-box"}></div>
      <div className={"access-management-header"}>
        <Title
          textAlign="center"
          subtitle="Access management"
          title="Ensuring security and compliance through effective access management"
        />
      </div>
      <div className={"project-level-authorization"}>
        <div className={"project-level-authorization-title"}>
          Project level authorization
        </div>
        <div className={"project-level-authorization-text"}>
          Project level authorization provides users with access to specific
          resources and data based on their roles within a particular project or
          team. This approach allows for more granular control over access
          rights, ensuring that users only have access to the resources they
          need to complete their work.
        </div>
        <div className={"project-level-authorization-roles"}>
          {roles.map((role, index) => (
            <Role
              key={index}
              icon={role.icon}
              title={role.title}
              abilities={role.abilities}
            />
          ))}
        </div>
        <div className={"SRAM-container"}>
          <div className={"surf-logo"}>
            <img src={SURFLogo} alt="SURFLogo" />
          </div>
          <div className={"project-level-authorization-text sram-text"}>
            <div className={"project-level-authorization-title sram-title"}>
              SURF Research Access Management
            </div>
            SURF Research Access Management (SRAM) is the new authentication and
            group management service developed by SURF. The main reason why we
            use it is to enable collaboration with anyone connected. With SRAM
            it is easier, faster and safer to collaborate with researchers
            around the world. Additionally, SRAM allows data stewards to control
            collaboration memberships and manage permissions on data stored at
            DataHub.
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AccessManagement;
