import React from "react";
import Homepage from "./pages/Homepage";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "./pages/Layout";
import Blog from "./pages/Blog";
import BlogPage from "./pages/BlogPage";
import ScrollToTop from "./components/common/ScrollToTop";

export default function App() {
  const location = useLocation();

  const getTitle = () => {
    switch (location.pathname) {
      case "/":
        return "MDR - Homepage";
      case "/blog":
        return "MDR - Blog";
      default:
        if (location.pathname.match(/\/blog\/(\d+)/)) {
          return "MDR - Blog page";
        } else {
          return "MDR - Homepage";
        }
    }
  };

  return (
    <>
      <Helmet>
        <title>{getTitle()}</title>
      </Helmet>
      <ScrollToTop />
      <Routes base="/">
        <Route path="/" element={<Layout />}>
          <Route index element={<Homepage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:pageId" element={<BlogPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </>
  );
}
