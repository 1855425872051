export const loginUrl = `${window.env.REACT_APP_MDR_URL}/saml2/login/`;
export const helpCenterUrl = `${window.env.REACT_APP_DATAHUB_HC_URL}`;
export const DataHubServiceDeskTickets = "https://mumc.atlassian.net/servicedesk/customer/user/requests?page=1&reporter=all&statuses=open";
export const dataHubPortalUrl = `${window.env.REACT_APP_DATAHUB_HOME_URL}`;
export const contactUsUrl = `${helpCenterUrl}/support`;
export const quickStartUrl = `${helpCenterUrl}/quickstart`;
export const CEDARUrl =
  "https://more.metadatacenter.org/tools-training/cedar-metadata-tools";
export const PIDUrl = `${helpCenterUrl}/access#Detailedguidanceforfindingandaccessingthedatayouneed-PersistentIdentifiers`;
export const collectionsUrl = `${quickStartUrl}#QuickstartguidetousingMaastrichtDataRepository-StoreData`;
export const privacyPolicyUrl = `${dataHubPortalUrl}/privacy-statement`;
export const termsOfServiceUrl = `${dataHubPortalUrl}/terms-of-service`;
export const linkedInUrl =
  "https://www.linkedin.com/company/datahubmaastricht/";
export const gitHubUrl = "https://github.com/MaastrichtUniversity";
export const emailUrl = "mailto:datahub@maastrichtuniverstity.nl";
export const emailUrlMUMC = "mailto:datahub@mumc.nl";
export const phoneUrl = "tel:0031433872844";
export const DMPUrl = "https://dmp.datahubmaastricht.nl/";
