import React from "react";
import StorageCard from "./StorageCard";
import STORAGE from "../../assets/storage.js";
import Section from "../common/Section";
import Title from "../common/Title";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const StorageSolutions = () => {
  const options = {
    rewind: true,
    start: 2,
    updateOnMove: true,
    perPage: 1,
    perMove: 1,
    type: "loop",
    focus: "center",
    trimSpace: "move",
    mediaQuery: "min",
    breakpoints: {
      768: {
        perPage: 4,
      },
    },
  };

  setTimeout(CarouselInitialization, 200);

  /**
   * Move Carousel to left and right to get proper alignment
   */
  function CarouselInitialization() {
    document
      .getElementsByClassName("splide__arrow splide__arrow--prev")[0]
      .click();
    document
      .getElementsByClassName("splide__arrow splide__arrow--next")[0]
      .click();
  }

  return (
    <Section color={"darkGrey"}>
      <Title
        subtitle="storage solutions"
        title="Storage that fits any needs"
        textAlign="center"
      />
      <p className="storage-solutions-text">
        If you have less than a 100GB of data, we can offer storage for free to
        you. Your data will be on our high performance storage facilities,
        hosted by Maastricht University. For larger data sets, we have several
        storage solutions available. Have a look below which solution is the
        best fit for your situation.
      </p>
      <Splide aria-label="Storage solutions" options={options}>
        {STORAGE.map((item) => (
          <SplideSlide key={item.key}>
            <StorageCard
              location={item.location}
              title={item.title}
              body={item.body}
              price={item.price}
            />
          </SplideSlide>
        ))}
      </Splide>
    </Section>
  );
};

export default StorageSolutions;
