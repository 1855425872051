import React from "react";
import Landing from "../components/homepage/Landing";
import GettingStarted from "../components/homepage/GettingStarted";
import DataManagementSupport from "../components/homepage/DataManagementSupport";
import DataStewards from "../components/homepage/DataStewards";
import AccessManagement from "../components/homepage/AccessManagement";
import StorageSolutions from "../components/homepage/StorageSolutions";
import DataPublication from "../components/homepage/DataPublication";
import SummarySection from "../components/blog/SummarySection";

function Homepage() {
  return (
    <>
      <Landing />
      <GettingStarted />
      <DataManagementSupport />
      <DataStewards />
      <AccessManagement />
      <DataPublication />
      <StorageSolutions />
      <SummarySection />
    </>
  );
}

export default Homepage;
