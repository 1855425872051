import React from "react";
import Section from "../components/common/Section";
import Title from "../components/common/Title";
import FetchArticles from "../components/blog/FetchArticles";

export default function Blog() {
  return (
    <Section name="blog">
      <Title
        textAlign="center"
        subtitle="Latest articles"
        title="Stay tuned for Maastricht Data Repository’s news and useful features"
      />
      <FetchArticles />
    </Section>
  );
}
