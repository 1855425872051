import Section from "../common/Section";
import "./datapublication.css";
import cedarIcon from "../../assets/icons/metadata.svg";
import pidIcon from "../../assets/icons/persistent_identifier.svg";
import collectionIcon from "../../assets/icons/collections.svg";
import React from "react";
import Title from "../common/Title";
import Divider from "../common/Divider";
import { CEDARUrl, PIDUrl, collectionsUrl } from "../../assets/urls";

const columns = [
  {
    icon: cedarIcon,
    title: "CEDAR powered metadata forms",
    text:
      "Our metadata forms are designed to be flexible and customizable, enabling users to define " +
      "their own metadata fields and values.",
    href: CEDARUrl,
    linkText: "Learn about CEDAR",
  },
  {
    icon: pidIcon,
    title: "Persistent identifiers",
    text:
      "Every collection in Maastricht Data Repository will get its own " +
      "persistent identifier (PID) which is a long-lasting reference to a " +
      "collection",
    href: PIDUrl,
    linkText: "Learn about PID's",
  },
  {
    icon: collectionIcon,
    title: " Immutable collections",
    text:
      "A collection in Maastricht Data Repository is immutable, the content is read-only and " +
      "cannot be changed.",
    href: collectionsUrl,
    linkText: "Learn about collections",
  },
];

const DataPublicationColumn = ({ icon, title, text, href, linkText }) => {
  return (
    <div className={"data-publication-column"}>
      <div className={"data-publication-column-icon"}>
        <img src={icon} alt="Cedar" />
      </div>
      <div className={"data-publication-column-title"}>{title}</div>
      <div className={"data-publication-column-text"}>{text}</div>
      <div className={"data-publication-column-more"}>
        <a href={href}>{linkText}</a>
      </div>
    </div>
  );
};

const DataPublication = () => {
  return (
    <Section color={"lightGrey"} name={"data-publication"}>
      <div className={"data-publication-header"}>
        <Title
          textAlign="center"
          subtitle="Data Publication"
          title="Sharing knowledge through data publication"
        />
      </div>
      <div className={"data-publication-columns"}>
        {columns.map((column, index) => (
          <DataPublicationColumn
            key={index}
            icon={column.icon}
            title={column.title}
            text={column.text}
            href={column.href}
            linkText={column.linkText}
          />
        ))}
      </div>
      <Divider grey />
    </Section>
  );
};

export default DataPublication;
