import Section from "../common/Section";
import "./getstarted.css";
import { ReactComponent as AS } from "../../assets/illustrations/access and store.svg";
import { ReactComponent as DC } from "../../assets/illustrations/data collaboration.svg";
import { ReactComponent as SDS } from "../../assets/illustrations/supporting data stewardship.svg";
import React, { useEffect, useRef, useState } from "react";
import Title from "../common/Title";
import { quickStartUrl } from "../../assets/urls";
import { motion, useAnimationControls, useInView } from "framer-motion";

export default function GettingStarted() {
  const [showAccessStoreIcon, setAccessStoreIcon] = useState(true);
  const [showDataCollaborationIcon, setDataCollaborationIcon] = useState(false);
  const [showDataStewardIcon, setDataStewardIcon] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref);
  const controls = useAnimationControls();

  const boxSwitchDurationSeconds = 5;

  /**
   * Function to locate the active box then
   * make next box active, disable current active box and update the icon
   */
  function nextBoxActive() {
    if (document.querySelector(".box.access-store.active")) {
      document.querySelector(".box.access-store").classList.remove("active");
      document.querySelector(".box.data-collaboration").classList.add("active");
      setAccessStoreIcon(false);
      setDataCollaborationIcon(true);
      setDataStewardIcon(false);
    } else if (document.querySelector(".box.data-collaboration.active")) {
      document
        .querySelector(".box.data-collaboration")
        .classList.remove("active");
      document
        .querySelector(".box.support-data-stewardship")
        .classList.add("active");
      setAccessStoreIcon(false);
      setDataCollaborationIcon(false);
      setDataStewardIcon(true);
    } else if (document.querySelector(".box.support-data-stewardship.active")) {
      document
        .querySelector(".box.support-data-stewardship")
        .classList.remove("active");
      document.querySelector(".box.access-store").classList.add("active");
      setAccessStoreIcon(true);
      setDataCollaborationIcon(false);
      setDataStewardIcon(false);
    }
  }

  useEffect(() => {
    const startAnimation = () => {
      controls.start({
        width: ["0%", "100%"],
        transition: { duration: boxSwitchDurationSeconds, ease: "linear" },
      });
    }

    if (isInView) {
      startAnimation();
    }
    const interval = setInterval(() => {
      if (isInView) {
        startAnimation()
        nextBoxActive();
      }
    }, boxSwitchDurationSeconds * 1000);
   
    return () => {
      clearInterval(interval);
    };
  }, [isInView, controls]);

  const Box = ({ className, title, text }) => {
    let isActive =
    (showAccessStoreIcon && className === "access-store") ||
    (showDataCollaborationIcon && className === "data-collaboration") ||
    (showDataStewardIcon && className === "support-data-stewardship")
      ? "active"
      : "";
      
    return (
      <div className={`box ${className} ${isActive}`}>
        <div className={"title"}>{title}</div>
        {text}
      </div>
    );
  };

  return (
    <Section name={"get-started"}>
      <Title
        textAlign="left"
        subtitle="Get started"
        title="Responsible research data management"
        content="Maastricht Data Repository is a data management platform designed to
        provide researchers with a convenient and secure place to store and
        manage their data. To get started, check out our"
        link={quickStartUrl}
        linkText="Quick start guide."
      />
      <div className={"boxes-wrapper"} ref={ref}>
        <div className={"boxes"}>
          <Box
            className={"access-store"}
            title={"Access & store"}
            text={"Store, view, download and search research data"}
          ></Box>
          <Box
            className={"data-collaboration"}
            title={"Data collaboration"}
            text={
              "An essential strategy for researchers to solve complex problems"
            }
          ></Box>
          <Box
            className={"support-data-stewardship"}
            title={"Supporting data stewardship"}
            text={
              "Promoting responsible management and sharing of data throughout its " +
              "lifecycle"
            }
          ></Box>
        </div>
        <div className="get-started-wrapper">
          <div className={"get-started-icon"}>
            {showAccessStoreIcon ? <AS key={"DS"} /> : null}
            {showDataCollaborationIcon ? <DC key={"DC"} /> : null}
            {showDataStewardIcon ? <SDS key={"SDS"} /> : null}
          </div>
        </div>
      </div>
      <div className="wrap">
        <motion.div className="bar" animate={controls} />
      </div>
    </Section>
  );
}
