import React from "react";
import { MdLocationPin } from "react-icons/md";
import "./storageCard.css";
import { contactUsUrl } from "../../assets/urls";

const StorageCard = (props) => {
  return (
    <div className="storage__card">
      <div className="storage__header">
        <h5>
          <MdLocationPin />
          {props.location}
        </h5>
        <h4> {props.title}</h4>
      </div>
      <div className="storage__body">
        <ul>
          {props.body.map((listItem, i) => (
            <li key={i}>{listItem}</li>
          ))}
        </ul>
      </div>
      <div className="storage__footer">
        {props.price ? (
          `€ ${props.price},- Tb/year`
        ) : (
          <a href={contactUsUrl}>Contact us</a>
        )}
      </div>
    </div>
  );
};

export default StorageCard;
