const STORAGE = [
  {
    key: 1,
    location: "Maastricht University Medical Center",
    title: "High Performance",
    body: [
      "For researchers with data that is legally bound to be stored inside the hospital.",
    ],
  },
  {
    key: 2,
    location: "Maastricht University",
    title: "High Performance",
    body: ["For researchers who need daily or constant access to their data."],
    price: 130,
  },
  {
    key: 3,
    location: "Maastricht University",
    title: "Medium Performance",
    body: [
      "For researchers who don't need constant but regular access to their data.",
    ],
    price: 62,
  },
  {
    key: 4,
    location: "Amsterdam, SURFsara",
    title: "Tape archive",
    body: ["For researchers who need in-frequent access to their data."],
    price: 17,
  },
];

export default STORAGE;
