import React, { useState } from "react";
import logo from "../../assets/logos/MDR_logo_white_no_text.png";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { MdOutlineHelp, MdOutlineClose, MdMenu } from "react-icons/md";
import { FaUser, FaNewspaper } from "react-icons/fa";
import { RiArrowUpSFill } from "react-icons/ri";
import "./navbar.css";
import { AnimatePresence, motion, useScroll } from "framer-motion";
import { loginUrl, helpCenterUrl } from "../../assets/urls";

const Navbar = () => {
  const { scrollYProgress } = useScroll();
  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  return (
    <div className="navbar__container">
      <div className="navbar__brand">
        <HashLink smooth to="/#home">
          <img src={logo} alt="Logo" />
        </HashLink>
      </div>
      <div className="mobile">
        <button onClick={handleDropdown}>
          {dropdown ? <MdOutlineClose /> : <MdMenu />}
        </button>
      </div>
      <AnimatePresence>
        {dropdown && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
            className={`dropdown ${dropdown ? "open" : ""}`}
          >
            <ul className="dropdown__links">
              <RiArrowUpSFill className="arrow-up" />
              <li>
                <Link id="blog" to="/blog">
                  <FaNewspaper size={20} />
                  Blog
                </Link>
              </li>
              <li>
                <a href={helpCenterUrl}>
                  <MdOutlineHelp size={20} />
                  Help Center
                </a>
              </li>
              <li>
                <a href={loginUrl}>
                  <FaUser />
                  Login
                </a>
              </li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="navbar__links_container large">
        <ul className="navbar__links">
          <li>
            <Link id="blog" to="/blog">
              <FaNewspaper size={20} />
              Blog
            </Link>
          </li>
          <li>
            <a id="help-center" href={helpCenterUrl}>
              <MdOutlineHelp size={20} />
              Help Center
            </a>
          </li>
          <li>
            <a id="login" href={loginUrl}>
              <FaUser />
              Login
            </a>
          </li>
        </ul>
      </div>
      <motion.div
        className="progress-bar"
        style={{ scaleX: scrollYProgress }}
      />
    </div>
  );
};

export default Navbar;
