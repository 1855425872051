import React from "react";
import "./blog-page.css"
import "../assets/fontawesome/css/all.css";
import Section from "../components/common/Section";
import FetchArticle from "../components/blog/FetchArticle";

export default function Blog() {
  return (
    <Section name="blog">
      <FetchArticle />
    </Section>
  );
}
