import React from "react";
import Section from "../common/Section";
import Title from "../common/Title";
import DataStewardIcon from "../../assets/icons/data_steward.svg";
import "./datastewards.css";

const DataStewards = () => {
  return (
    <Section color={"dark"}>
      <Title
        subtitle="Data stewards in control"
        title="Your first point of contact"
        content="A data steward is responsible for the long-term curation of your dataset.
        They can set access permissions and enable or disable certain features in your project."
        textAlign="left"
      />
      <div className="datastewards__wrapper">
        <div className="datastewards__logo">
          <img src={DataStewardIcon} alt="Data steward" />
        </div>
        <div className="datastewards__content">
          <div className="datastewards__content_small" data-aos="fade-up">
            <h5>What is the role of data steward?</h5>
            <p>
              The role of a data steward is to ensure that data is managed
              responsibly and effectively throughout its lifecycle. They will
              serve as the first point of contact for your department or
              researchgroup.
            </p>
          </div>
          <div className="datastewards__content_small" data-aos="fade-up">
            <h5>What if I don't have a data steward?</h5>
            <p>
              Your department might not have a data steward yet, or you do not
              know who it is. We have support staff available to help you move
              forward, please contact us to see if our data steward can be of
              assistance.
            </p>
          </div>
          <div className="datastewards__content_small" data-aos="fade-up">
            <h5>How to become a data steward?</h5>
            <p>
              Are you exited to get started with data management and do you want
              to help your collegues? Join as a data steward! Please use the
              contact form to let us know so we can get the process started.
            </p>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default DataStewards;
