import Section from "../common/Section";
import "./datamanagementsupport.css";
import orangeShape from "../../assets/shapes/orange-4.svg";
import blueShape from "../../assets/shapes/dark blue-5.png";
import projects from "../../assets/icons/projects.svg";
import metadata from "../../assets/icons/metadata.svg";
import dropzones from "../../assets/icons/dropzone.svg";
import collaborate from "../../assets/icons/collaborate.svg";
import Title from "../common/Title";

const BoxLeft = ({ icon, title, text }) => {
  return (
    <div data-aos="zoom-in" className={"data-management-support-box"}>
      <div className={"data-management-support-box-image"}>
        <img src={icon} alt="Icon" />
      </div>
      <div className={"data-management-support-box-title"}>{title}</div>
      <div className={"data-management-support-box-text"}>{text}</div>
    </div>
  );
};

const BoxRight = ({ icon, title, text }) => {
  return (
    <div data-aos="zoom-in" className={"data-management-support-box-right"}>
      <div className={"data-management-support-box-title-right"}>{title}</div>
      <div className={"data-management-support-box-text-right"}>{text}</div>
      <div className={"data-management-support-box-image-right"}>
        <img src={icon} alt="Icon" />
      </div>
    </div>
  );
};

const DataManagementSupport = () => {
  return (
    <Section name={"data-management-support"} border="top">
      <div className="custom-shape-orange">
        <img src={orangeShape} alt="Orange Shape" />
      </div>
      <div className={"data-management-support-header"}>
        <Title
          textAlign="center"
          subtitle="Data management support"
          title="Effortlessly organize, share, and access data"
        />
      </div>
      <div className={"data-management-support-content"}>
        <BoxLeft
          icon={projects}
          title={"Structure data in projects"}
          text={
            "A project categorizes data together with each project consisting of " +
            "one or more datasets, which we refer to as collections."
          }
        ></BoxLeft>
        <BoxRight
          icon={metadata}
          title={"Keep your metadata up to date"}
          text={
            "Metadata makes it easier to find and access data by providing key " +
            "information about the data, such as title, author and the date of " +
            "creation."
          }
        ></BoxRight>
        <BoxLeft
          icon={dropzones}
          title={"Use dropzones"}
          text={
            "A dropzone is a specific, temporary location within our system where " +
            "users can securely upload, rename, organize and delete the data " +
            "until it is ready to be ingested to a collection."
          }
        ></BoxLeft>
        <BoxRight
          icon={collaborate}
          title={"Collaborate"}
          text={
            "Manage your projects permissions to enable seamless collaboration " +
            "within your organization, department or even with external researchers."
          }
        ></BoxRight>
      </div>
      <div className="custom-shape-blue">
        <img src={blueShape} alt="Blue Shape" />
      </div>
    </Section>
  );
};

export default DataManagementSupport;
