import "./footer.css";
import FooterLogos from "./FooterLogos";
import Button from "./Button";
import { HashLink } from "react-router-hash-link";
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import {
  helpCenterUrl,
  dataHubPortalUrl,
  privacyPolicyUrl,
  termsOfServiceUrl,
  DataHubServiceDeskTickets,
  gitHubUrl,
  linkedInUrl,
  emailUrl,
  emailUrlMUMC,
  phoneUrl,
  DMPUrl,
} from "../../assets/urls";

export default function Footer() {
  return (
    <>
      <section id="contact">
        <div className="footer__content">
          <div className="footer__header">
            <FooterLogos />
            <h4>
              <a href={helpCenterUrl + "/support"}>
                <Button
                  className="primary"
                  text="Have a question, remark or request? Contact us"
                />
              </a>
            </h4>
          </div>

          <div className="footer__body flex">
            <div className="footer__body_item">
              <h5>Address</h5>
              <p>
                DataHub Maastricht <br />
                Paul-Henri Spaaklaan 1 (3rd floor) <br />
                6229 EN Maastricht <br />
                Nederland
              </p>
            </div>
            <div className="footer__body_item">
              <h5>Contact details</h5>
              <a href={phoneUrl}>
                <p>0031 43 3872844</p>
              </a>
              <a href={emailUrl}>
                <p>datahub@maastrichtuniversity.nl</p>
              </a>
              <a href={emailUrlMUMC}>
                <p>datahub@mumc.nl</p>
              </a>
            </div>
            <div className="footer__body_item">
              <h5>Resources</h5>
              <a href={privacyPolicyUrl}>
                <p>Privacy policy</p>
              </a>
              <a href={termsOfServiceUrl}>
                <p>Terms of service</p>
              </a>
              <a href={helpCenterUrl}>
                <p>Help center</p>
              </a>
              <a href={DataHubServiceDeskTickets}>
                <p>Support requests</p>
              </a>
            </div>
            <div className="footer__body_item">
              <h5>Tools</h5>
              <HashLink smooth to="/#home">
                <p>Maastricht Data Repository</p>
              </HashLink>
              <a href={DMPUrl}>
                <p>DMP Maastricht</p>
              </a>
              <a href={dataHubPortalUrl}>
                <p>DataHub</p>
              </a>
            </div>
          </div>
        </div>

        <hr className="footer__border" />
        <div className="footer__footer">
          © 2023, made by DataHub <br />
          <a href={linkedInUrl}>
            <AiFillLinkedin className="linkedin" />
          </a>
          <a href={gitHubUrl}>
            <AiFillGithub className="github" />
          </a>
        </div>
      </section>
    </>
  );
}
