import Section from "../common/Section";
import logo from "../../assets/logos/MDR_logo_white_no_text.png";
import greenShape from "../../assets/shapes/green-6.svg";
import "./landing.css";
import Divider from "../common/Divider";
import { FaUser } from "react-icons/fa";
import { loginUrl, contactUsUrl } from "../../assets/urls";

const Landing = () => {
  return (
    <Section color="dark" name="landing">
      <div id="home" className="header">
        <div className="header__titles">
          <h1>Maastricht Data Repository</h1>
          <h2>Research data management made simple</h2>
          <div className="header__button_container">
            <a className="header__button primary" href={contactUsUrl}>
              Contact us
            </a>
            <a className="header__button secondary" href={loginUrl}>
              Login
              <FaUser />
            </a>
          </div>
        </div>
        <div className="header__logo">
          <img src={logo} alt="Logo" />
        </div>
        <div className="custom-shape-green">
          <img src={greenShape} alt="Green Shape" />
        </div>
      </div>
      <Divider />
    </Section>
  );
};

export default Landing;
